import { ref } from "vue";
const routes_home_list = ref([
	{
		path: "/home/",
		alias: "/",
		name: "home",
		// route level code-splitting
		// this generates a separate chunk (home.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
		children: [
			{
				path: "/home/",
				alias: "/",
				name: "home",
				component: () => import(/* webpackChunkName: "home" */ "@/views/Home/H_0.vue")
			},
			{
				path: "/home/h_1",
				name: "h_1",
				component: () => import("@/views/Home/H_1/H_1.vue")
			},

			{
				path: "/home/h_2",
				name: "h_2",
				component: () => import("@/views/Home/H_2/H_2.vue")
			},
			{
				path: "/home/h_3",
				name: "h_3",
				component: () => import("@/views/Home/H_3/H_3.vue")
			}
		]
	}
]);

const routes_home = routes_home_list.value;

export default routes_home[0];
