import { ref } from "vue";
const routes_aliment_list = ref([
	{
		path: "/aliments",
		name: "aliments",
		// route level code-splitting
		// this generates a separate chunk (aliments.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "aliments" */ "@/views/AlimentsView.vue"),
		children: [
			{
				path: "/aliments",
				name: "CatTree1",
				component: () => import(/* webpackChunkName: "CatTree1" */ "@/views/Aliments/CatTree1.vue")
			},
			{
				path: "/aliments/c_a",
				name: "CompositionAliments",
				component: () => import(/* webpackChunkName: "CompositionAliments" */ "@/views/Aliments/c_a/CompositionAliments.vue"),
				children: [
					{
						path: "/aliments/c_a",
						name: "CatTree2Ca",
						component: () => import(/* webpackChunkName: "CatTree2Ca" */ "@/views/Aliments/c_a/CatTree2Ca.vue")
					},
					{
						path: "/aliments/c_a_list/:id",
						name: "CatTree3Ca",
						component: () => import(/* webpackChunkName: "CatTree3Ca" */ "@/views/Aliments/c_a/CatTree3Ca.vue")
					}
				]
			},
			{
				path: "/aliments/c_s",
				name: "CorrespondanceEnSucre",
				component: () => import(/* webpackChunkName: "CorrespondanceEnSucre" */ "@/views/Aliments/c_s/CorrespondanceEnSucre.vue"),
				children: [
					{
						path: "/aliments/c_s",
						name: "CatTree2Cs",
						component: () => import(/* webpackChunkName: "CatTree2Cs" */ "@/views/Aliments/c_s/CatTree2Cs.vue")
					},
					{
						path: "/aliments/c_s_list/:id",
						name: "CatTree3Cs",
						component: () => import(/* webpackChunkName: "CatTree3Ca" */ "@/views/Aliments/c_s/CatTree3Cs.vue")
					}
				]
			}
		]
	}
]);

const routes_aliment = routes_aliment_list.value;

export default routes_aliment[0];
