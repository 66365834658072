<template>
  <div
    v-motion
    :initial="{
      opacity: 0,
      y: 10,
    }"
    :enter="{
      opacity: 1,
      y: 0,

      transition: {
        delay: `${trDl}`,
        duration: `${trDr}`,
      },
    }"
    :leave="{
      opacity: 0,
      y: -10,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MotionBase",
  props: {
    oI: Number,
    oE: Number,
    sI: Number,
    sE: Number,
    yI: Number,
    yE: Number,
    trDr: Number,
    trDl: Number,
  },
};
</script>
