<template>
  <div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <div class="fixed inset-0 overflow-y-auto">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-300 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              @click="closeModal"
              class="customFade fixed inset-0 bg-white bg-opacity-10 backdrop-filter backdrop-blur-md"
            />
          </TransitionChild>
          <div class="fixed z-50 bottom-4 left-4 right-4 p-4 text-center">
            <MotionBaseComponent
              :oI="0"
              :yI="155"
              :sI="0.99"
              :oE="1"
              :yE="0"
              :sE="1"
              :trDl="$transitionDl * 2"
              :trDr="$transitionDr * 2"
              :sL="0"
              :oL="0"
              class="w-full mx-auto"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="relative mx-auto w-full max-w-md transform rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all"
                >
                  <button
                    class="absolute right-0 -top-12 bg-indigo-900 text-white rounded-lg py-2 px-2"
                    @click="closeModal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  <!-- <DialogTitle
                    as="h3"
                    class="text-lg font-semibold leading-6 text-gray-900"
                  >
                  </DialogTitle> -->
                  <div class="mb-6">
                    <ul>
                      <li>
                        <router-link
                          to=""
                          class="inline-flex w-full justify-start rounded-lg border border-transparent bg-indigo-100 px-4 py-2 text-sm font-semibold text-indigo-600 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"
                            />
                          </svg>
                          <span class="ml-4">Partager l'application</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>

                  <div class="mt-2">
                    <button
                      type="button"
                      class="inline-flex w-full justify-start rounded-lg border border-transparent bg-red-100 px-4 py-2 text-sm font-semibold text-red-600 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      @click="closeModal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="ml-4"> Me deconnecter </span>
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </MotionBaseComponent>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
export default {
  name: "MenuBarBtns",
  props: {
    isOpen: Boolean,
    closeModal: Function,
  },

  setup(props) {
    // functions
    props.closeModal();
  },
};
</script>
