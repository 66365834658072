import { ref } from "vue";
const routes_regime_list = ref([
	{
		path: "/regime",
		name: "regime",
		// route level code-splitting
		// this generates a separate chunk (regime.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "regime" */ "@/views/RegimeView.vue"),
		children: [
			{
				path: "/regime",
				alias: "/r_0",
				name: "R_0",
				component: () => import(/* webpackChunkName: "R_0" */ "@/views/Regime/R_0.vue")
			},
			{
				path: "/regime/r_1",
				alias: "/r_0/r_1",
				name: "R_1",
				component: () => import(/* webpackChunkName: "R_1" */ "@/views/Regime/R_1/R_1.vue"),
				children: [
					{
						path: "/regime/r_1",
						alias: "/r_0/r_1",
						name: "R_1",
						component: () => import(/* webpackChunkName: "R_1" */ "@/views/Regime/R_1/R_1.vue")
					}
				]
			},

			{
				path: "/regime/r_2",
				alias: "/r_0/r_2",
				name: "R_2",
				component: () => import(/* webpackChunkName: "R_2" */ "@/views/Regime/R_2/R_2.vue"),
				children: [
					{
						path: "/regime/r_2",
						alias: "/r_0/r_2",
						name: "R_2",
						component: () => import(/* webpackChunkName: "R_2" */ "@/views/Regime/R_2/R_2.vue")
					}
				]
			}
		]
	}
]);

const routes_regime = routes_regime_list.value;

export default routes_regime[0];
