import { createStore } from "vuex";

const store = createStore({
  state: {
    //All global
    currentPage: "",
    currentPage__isLogo: true,
    modalState: false,
    modalStateAlimentDetails: false,

    //All Aliments
    currentCatCa: "",
    currentCatCs: "",

    //All Suivie
    resIMC: 0,
    poids: null,
    taille: null,

    //All PWA Control state
    ifUpdatefound: false,
    isOffline: false,
    userConnected: true,
  },
});

export default store;
