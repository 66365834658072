<template>
  <transition
    enter-active-class="transition duration-300 ease-out"
    enter-from-class=" overflow-hidden transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-300 ease-out"
    leave-from-class=" overflow-hidden transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <slot></slot>
  </transition>
</template>
<script>
export default {
  name: "MotionDisclosure",
};
</script>
