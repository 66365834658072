<template>
  <div class="menuBar">
    <MenuBarBtns :isOpen="$storeState.modalState" :closeModal="closeModal" />

    <div class="flex justify-between">
      <div>
        <MotionBase :trDl="$transitionDl" :trDr="$transitionDr">
          <img
            v-if="$storeState.currentPage__isLogo"
            src="/assets/img/logo/2.png"
            alt=""
            srcset=""
            width="40"
            class="-border-4 -rounded-full -border-indigo-500"
          />
          <span v-else>
            <button
              @click="backLink()"
              class="flex border-2 border-white focus:border-indigo-100 focus:text-indigo-600 py-1 px-2 rounded-lg bg-white justify-between text-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
              <!-- <span class="ml-1 font-bold">Retour</span> -->
            </button>
          </span>
        </MotionBase>
      </div>
      <MotionBase :trDl="$transitionDl" :trDr="$transitionDr">
        <h1 class="text-sm mt-1.5 font-bold">
          <span v-html="$storeState.currentPage"></span>
        </h1>
      </MotionBase>

      <div class="cursor-pointer">
        <MotionBase :trDl="$transitionDl" :trDr="$transitionDr">
          <router-link
            to="#"
            @click="openModal()"
            class="border-2 border-white focus:border-indigo-100 focus:text-indigo-600 flex py-1 px-2 rounded-lg bg-white justify-between text-sm"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </router-link>
        </MotionBase>
      </div>
    </div>
  </div>
</template>
<script>
import MenuBarBtns from "./MenuBarBtns.vue";

export default {
  name: "MenuBar",
  components: { MenuBarBtns },
  methods: {
    async openModal() {
      this.$storeState.modalState = true;
      console.log(this.$storeState.modalState);
    },
    async closeModal() {
      this.$storeState.modalState = false;
      console.log(this.$storeState.modalState);
    },
    async backLink() {
      this.$router.back();
    },
  },
  setup() {
    return {};
  },
};
</script>
