<template>
  <div class="relative">
    <nav
      class="bg-white relative mShadow-2 rounded-md p-4 mx-auto max-w-md flex justify-center space-x-4 text-sm"
    >
      <div v-for="route in routes" :key="route">
        <router-link :to="route.link">
          <div
            class="text-gray-500 flex overflow-hidden justify-center justify-items-center py-2 px-3 rounded-lg"
          >
            <span v-html="route.iconL[0]"> </span>
            <span v-html="route.iconL[1]"></span>
            <span class="text-sm my-auto mActive--menu-text">
              {{ route.name }}
            </span>
          </div>
        </router-link>
      </div>
    </nav>
    <div class="mShadow--custom-1"></div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";

var svgHome = reactive([
  '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-auto mr-1 unactive" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>',
  '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-auto mr-1 active" viewBox="0 0 20 20" fill="currentColor"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" /></svg>',
]);
var svgAliments = reactive([
  '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-auto mr-1 unactive" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" /></svg>',
  '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-auto mr-1 active" viewBox="0 0 20 20" fill="currentColor"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" /></svg>',
]);
var svgSuivie = reactive([
  '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-auto unactive" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" /></svg>',
  '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-auto active" viewBox="0 0 20 20" fill="currentColor"><path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" /></svg>',
]);

var SVGRegime = reactive([
  '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-auto mr-1 unactive" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" ><path stroke-linecap="round" stroke-linejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" /></svg>',
  '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-auto mr-1 active" viewBox="0 0 24 24" fill="currentColor" ><path d="M5.566 4.657A4.505 4.505 0 016.75 4.5h10.5c.41 0 .806.055 1.183.157A3 3 0 0015.75 3h-7.5a3 3 0 00-2.684 1.657zM2.25 12a3 3 0 013-3h13.5a3 3 0 013 3v6a3 3 0 01-3 3H5.25a3 3 0 01-3-3v-6zM5.25 7.5c-.41 0-.806.055-1.184.157A3 3 0 016.75 6h10.5a3 3 0 012.683 1.657A4.505 4.505 0 0018.75 7.5H5.25z" /></svg>',
]);

export default {
  name: "NavBar",
  setup() {
    const routes = reactive([
      { name: "Accueil", link: "/home", iconL: svgHome },
      { name: "Régime", link: "/regime", iconL: SVGRegime },
      { name: "Aliments", link: "/aliments", iconL: svgAliments },
      { name: "Suivies", link: "/suivie", iconL: svgSuivie },
    ]);
    return {
      routes,
    };
  },
};
</script>
