<template>
  <div class="app">
    <div class="max-w-md mx-auto mb-20 sm:mb-28">
      <div v-if="!$storeState.modalState" class="fixed z-50 bottom-4 left-4 right-4">
        <MotionBaseComponent
          :oI="0"
          :yI="105"
          :sI="0.9"
          :oE="1"
          :yE="0"
          :sE="1"
          :trDl="$transitionDl * 2"
          :trDr="$transitionDr * 2"
          :sL="0"
          :oL="0"
        >
          <NavBar />
        </MotionBaseComponent>
      </div>

      <div class="sm:p-0 p-4 sm:pt-4">
        <div class="relative overflow-hidden">
          <MenuBar />
        </div>
        <main class="">
          <router-view v-slot="{ Component }">
            <transition name="scale" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/base/NavBar.vue";

export default {
  name: "AppView",
  components: {
    NavBar,
  },
};
</script>
