<template>
  <Disclosure
    as="div"
    class="first:mt-0 mt-2 last:border-none last:pb-0 pb-8 border-b border-b-gray-200"
    v-slot="{ open }"
  >
    <DisclosureButton class="flex w-full justify-between rounded-lg text-left">
      <div>
        <h2>{{ q }}</h2>
      </div>

      <div>
        <PlusSmIcon v-show="!open" class="h-5 w-5 ml-4 text-gray-500" />
        <MinusSmIcon v-show="open" class="h-5 w-5 ml-4 text-indigo-700" />
      </div>
    </DisclosureButton>
    <MotionDisclosure>
      <DisclosurePanel class="pt-2 pb-0 r-content text-format">
        <p class="mt-4" v-html="r"></p>

        <DisclosureButton
          class="text-indigo-700 mt-4 py-2 font-semibold flex justify-start items-center space-x-2"
        >
          <span>Fermer</span>
          <span>
            <ChevronUpIcon class="h-5 w-5" />
          </span>
        </DisclosureButton>
      </DisclosurePanel>
    </MotionDisclosure>
  </Disclosure>
</template>
<script>
import { PlusSmIcon, MinusSmIcon } from "@heroicons/vue/solid";

export default {
  name: "DisclosureDetails",
  components: { PlusSmIcon, MinusSmIcon },
  props: {
    q: String,
    r: String,
  },
  setup() {},
};
</script>
