import { createRouter, createWebHistory } from "vue-router";
import AppView from "../views/AppView.vue";

import StarterView from "../views/control/StarterView.vue";
import store from "@/store/store";
import routes_home from "./routes/home.js";
import routes_regime from "./routes/regime.js";
import routes_aliment from "./routes/aliment.js";

const routes = [
	{
		path: "/",
		name: "app",
		component: AppView,

		children: [
			routes_home,
			routes_aliment,
			{
				path: "/suivie",
				name: "suivie",
				// route level code-splitting
				// this generates a separate chunk (suivie.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "suivie" */ "../views/SuivieView.vue")
			},
			// Route vers la page de connexion
			{
				path: "/log",
				name: "log",
				component: () => import( "../views/Login/LoggerPage.vue")
			},
			routes_regime,
			{
				path: "/:catchAll(.*)",
				name: "NotFound",
				component: () => import(/*webpackChunkName:"NotFound"*/ "../views/states/NotFound.vue")
			}
		]
	},
	{
		path: "/starter",
		name: "starter",
		component: StarterView
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach(async (to) => {
	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = ["/starter"];
	const authRequired = !publicPages.includes(to.path);
	const auth = store.state.userConnected;

	if (authRequired && !auth) {
		return "/starter";
	}
});

export default router;
