<template>
  <div
    v-motion
    :initial="{
      opacity: oI,
      y: yI,
      scale: sI,
    }"
    :enter="{
      opacity: oE,
      y: yE,
      scale: sE,
      transition: {
        delay: `${trDl}`,
        duration: `${trDr}`,
      },
    }"
    :leave="{
      opacity: oL,
      y: yL,
      scale: sL,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MotionBaseComponent",
  props: {
    oI: Number,
    oE: Number,
    sI: Number,
    sE: Number,
    yI: Number,
    yE: Number,
    oL: Number,
    yL: Number,
    sL: Number,
    trDr: Number,
    trDl: Number,
  },
};
</script>
