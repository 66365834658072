<template>
  <div>
    <h1 class="text-6xl">Cikomola Med Starter</h1>
  </div>
</template>

<script>
export default {
  name: "StarterView",
};
</script>
