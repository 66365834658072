import { createApp } from "vue";
import { MotionPlugin } from "@vueuse/motion";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import MotionBase from "./components/motions/MotionBase.vue";
import MotionBaseComponent from "./components/motions/MotionBaseComponent.vue";
import MotionDisclosure from "./components/motions/MotionDisclosure.vue";
import DisclosureDetails from "./components/base/DisclosureDetails.vue";
import MenuBar from "./components/base/MenuBar.vue";
import MenuBarBtns from "./components/base/MenuBarBtns.vue";

import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogOverlay, Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronUpIcon } from "@heroicons/vue/solid";

import store from "@/store/store";

const app = createApp(App);

app.use(router);
// https://motion.dev/vue/examples
app.use(MotionPlugin);
app.component("MotionBase", MotionBase);
app.component("MotionBaseComponent", MotionBaseComponent);
app.component("MotionDisclosure", MotionDisclosure);
app.component("DisclosureDetails", DisclosureDetails);

app.component("TransitionRoot", TransitionRoot);
app.component("TransitionChild", TransitionChild);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Dialog", Dialog);
app.component("DialogPanel", DialogPanel);
app.component("DialogOverlay", DialogOverlay);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Disclosure", Disclosure);
app.component("DisclosurePanel", DisclosurePanel);
app.component("DisclosureButton", DisclosureButton);
app.component("ChevronUpIcon", ChevronUpIcon);

app.component("MenuBar", MenuBar);
app.component("MenuBarBtns", MenuBarBtns);

import cat_tree_2_cs from "@/store/data/cat_tree_2_cs.json";
app.config.globalProperties.$cat_tree_2_cs = cat_tree_2_cs;

import cat_tree_2_ca from "@/store/data/cat_tree_2_ca.json";
app.config.globalProperties.$cat_tree_2_ca = cat_tree_2_ca;

import data_aliments_ca from "@/store/data/data_aliments_ca.json";
import data_aliments_cs from "@/store/data/data_aliments_cs.json";

app.config.globalProperties.$data_aliments_ca = data_aliments_ca;
app.config.globalProperties.$data_aliments_cs = data_aliments_cs;

app.config.globalProperties.$transitionDl = 100;
app.config.globalProperties.$transitionDr = 200;
app.config.globalProperties.$storeState = store.state;

app.mount("#app");
